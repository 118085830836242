(function ($) {
  if ($('.text-with-image__popup').length > 0) {
    $(".text-with-image").each(function(){
      const popUpButton = $(this).find('.popup-button');
      const popUpWindow = $(this).find('.text-with-image__popup');
      const popUpClose  = $(this).find('.text-with-image__popup-close-button');

      popUpButton.on('click', function(e){
        e.preventDefault();
        popUpWindow.addClass('show');
      });
      popUpClose.on('click', function(e){
        e.preventDefault();
        popUpWindow.removeClass('show');
      });      

    });
  }

})(jQuery);
