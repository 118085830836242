import { waitForFinalEvent } from "./betterAwaits.js";

(function($) {

	if( $('.mobile-accordion__title').length > 0 ) {
		var mobileFooter = {
			init: function(){
				$('.mobile-accordion__title').each(function(){
					$(this).on('click',function(){
						$(this).toggleClass('active');
						$(this).next('div').toggleClass('active');
					});
			 	});			
			}
		};

		mobileFooter.init();

	}
})(jQuery);